
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import FilterOnFields from '@/components/common/FilterOnFields.vue';
import PaginationPageSizeControl from '@/components/common/PaginationPageSizeControl.vue';
import { humanizeString } from '@/common/utilities';

@Component({ components: { FilterOnFields, PaginationPageSizeControl } })
export default class DataTable extends Vue {
  // data-table props
  @Prop({ default: true, type: Boolean }) private showFilter: boolean;
  @Prop({ default: true, type: Boolean }) private showPagination: boolean;
  @Prop({ default: false, type: Boolean }) private showHeaderFilter: boolean;
  @Prop({ default: false, type: Boolean }) private isFetchingAdditionalRows: boolean;
  @Prop({ default: true, type: Boolean }) private ignoreFilterCasing: boolean;
  @Prop({ default: false, type: Boolean }) private headerFilterPlaceholderText: boolean;
  @Prop({ type: Number, default: 500 }) debounce: number;
  // b-table props
  @Prop({ default: false }) private small: boolean;
  @Prop() items: any[];
  @Prop() fields: any[];
  @Prop() filteredIgnoredFields: any[];
  @Prop() initialSort: string;
  @Prop({ default: false }) private sortDesc: boolean;
  @Prop({ default: '625px' }) private stickyHeaderSize: string;
  @Prop({ default: true, type: Boolean }) private showEmpty: boolean;
  @Prop({ default: 1 }) private currentPage: number;
  @Prop({ default: 10 }) private currentPageSize: any;
  @Prop({ type: Function, default: undefined }) filterFunction: (row: any, filter: string) => boolean;
  // local props
  filter: string = '';
  totalRows = 1;
  filters: any = {};
  filterOnFields: any[] = [];
  dataTableSortBy: string = '';
  dataTableSortDesc: boolean = false;
  dataTableCurrentPage: number = 1;
  dataTableCurrentPageSize: any = 10;

  mounted() {
    this.dataTableSortBy = this.initialSort;
    this.dataTableSortDesc = this.sortDesc;
    this.dataTableCurrentPage = this.currentPage;
    this.dataTableCurrentPageSize = this.currentPageSize;
  }

  @Watch('items', { immediate: true })
  onItemsChange(newValue: any[], oldValue: any[]) {
    this.totalRows = !!newValue ? newValue.length : 0;
  }

  onFiltered(filteredItems: any[]) {
    this.totalRows = filteredItems.length;
    if (this.currentPage > this.maxPageSize) {
      this.currentPage = 1;
    }
  }

  get maxPageSize() {
    if (this.items) {
      return this.items.length;
    }
  }

  get showGrid() {
    return this.showEmpty ? true : this.items.length > 0;
  }

  filterOnFieldsChanged(filterOnFields: string[]) {
    this.filterOnFields = filterOnFields;
    this.$emit('filterOnFieldsChanged', this.filterOnFields);
  }

  pageToFirst(): void {
    this.currentPage = 1;
  }

  filteredItems() {
    if (!this.items || this.items.length === 0) {
      return [];
    }

    if (!this.showHeaderFilter) {
      return this.items;
    }
    
    return this.applyFilter();
  }

  applyFilter() {
    const filtered = this.items.filter((item) => {
      return Object.keys(this.filters).every((key) => {
        const fieldKey = this.getFieldKey(key);
        let itemValue = this.getValueFromMapping(item, fieldKey);
        const filterValue = String(this.filters[fieldKey]).trim();

        // Check if the key has a type property and use it to get the value from the enum
        const field = this.fields.find(f => this.getFieldKey(f) === fieldKey);
        if (field && field.type) {
          itemValue = field.type[itemValue];
        }
        
        itemValue = this.convertToString(itemValue).trim();

        // Handle case sensitivity
        if (this.ignoreFilterCasing) {
          return itemValue.toLowerCase().includes(filterValue.toLowerCase());
        } else {
          return itemValue.includes(filterValue);
        }
      });
    });

    if (filtered.length === 0) {
      return [];
    }

    this.onFiltered(filtered);
    return filtered;
  }

  getFieldKey(field: any): string {
    return typeof field === 'string' ? field : field.key;
  }

  getFieldLabel(field: any): string {
    if (!this.headerFilterPlaceholderText) {
      return '';
    }

    return typeof field === 'string' ? humanizeString(field) : field.label;
  }

  convertToString(value: any): string {
    if (Array.isArray(value)) {
      return value.map((val) => (typeof val === 'object' && val !== null ? JSON.stringify(val) : String(val))).join(', ');
    } else if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    } else {
      return String(value);
    }
  }

  getValueFromMapping(obj: any, mappingKey: any): any {
    const keys = mappingKey.split('.');
    let value = obj;

    for (const key of keys) {
      if (value && key in value) {
        value = value[key];
      } else {
        return undefined;
      }
    }

    return value;
  };

  @Watch('currentPageSize', { immediate: true })
  updatePageSize(newValue: any, oldValue: any) {
    this.pageSizeChanged(newValue);
  }

  pageSizeChanged(pageSize: any) {
    this.dataTableCurrentPageSize = pageSize;
  }
}


import Vue from 'vue';
import ServiceCenterLocation from '@/components/ServiceCenters/ServiceCenterLocation.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfigEditComponent from '@/components/configuration/ConfigEditComponent.vue';
import {
  SelectOption,
  ServiceCenterLocationConfig,
  ServiceLocationAddress,
  ServiceLocationContactData,
  ServiceLocationMakes,
  StoreHours,
} from '@/store/globalmodules/types';
import {
  SAVE_SERVICECENTER_LOCATION,
  FETCH_SERVICECENTER_STATE,
} from '@/store/constants';
import { EquipmentType } from '@/store/dynamicfulfillment/types';

@Component({
  components: { ConfigEditComponent, ServiceCenterLocation },
})
export default class ServiceCenterLocationAdd extends Vue {
  @Prop() private id!: string;
  private isValidState: boolean = true;
  private isStateChanged: boolean;
  private storeHours: StoreHours[] = [];
  private selectedLocationMakes: string[] = null;
  private selectedLocationEquipmentTypes: EquipmentType[] = null;
  private submitForm: boolean = false;
  private address: ServiceLocationAddress = {
    line1: '',
    line2: '',
    line3: '',
    city: '',
    region: '',
    country: '',
    postalCode: '',
    latitude: '',
    longitude: '',
  };
  private contactData: ServiceLocationContactData = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
  };

  private defaultConfig: ServiceCenterLocationConfig = {
    id: null,
    eTag: null,
    storeId: '',
    name: '',
    contactInfo: this.contactData,
    serviceNetwork: this.$route.query.serviceNetwork ? this.$route.query.serviceNetwork as string : '',
    servicedManufacturers: this.selectedLocationMakes,
    isActive: true,
    address: this.address,
    openHours: this.storeHours,
    ratingSummary: { rating: null, numberOfRatings: null },
    warrantyDays: 0,
    customAttributes: {},    
    serviceArea: [],
    servicedEquipmentTypes: this.selectedLocationEquipmentTypes,
    billingVendorId: '',
  };

  private isConfigError: boolean = false;

  private get fetchAction() {
    return FETCH_SERVICECENTER_STATE;
  }

  private get saveAction() {
    return SAVE_SERVICECENTER_LOCATION;
  }

  private formState(value: boolean) {
    this.isValidState = value;
  }

  private stateUpdated() {
    this.isStateChanged = true;
  }

  private stateSaved() {
    this.isStateChanged = false;
  }

  private beforeRouteLeave(to: any, from: any, next: any) {
    if (this.isStateChanged) {
      const answer = window.confirm(
        'Do you really want to leave? you have unsaved changes!',
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }

  private formSubmit(e: any) {
    this.submitForm = !this.submitForm;
  }

  private configError() {
    this.isConfigError = !this.isConfigError;
  }

  private onConfigChange(request: { config: ServiceCenterLocationConfig | null; isValid: boolean }) {
    this.isValidState = request.isValid;
    if (request.isValid) {
      this.defaultConfig = request.config;
    }
  }
}
